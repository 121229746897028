const vuexInit = {
    platMap: {
        'train': {
            id: 373,
            name: "师资培训",
            logo: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/zhongtuo/zhongtuoLogo.png',
            faviconurl: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/favicon_tuoyu.ico',
        },
        'jiazhang': { //改成0-6岁家长研修社
            id: 379,
            name: "育儿学堂",
            logo: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/zhongtuo/zhongtuojiazhangLogo4.png',
            faviconurl: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/favicon_chongdong.ico'
        },
    }
  }
  export default vuexInit